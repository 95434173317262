/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-22 11:18:16
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';
import { peopleType } from '@/utils/options';
import { getLabel } from '@/utils/options';
export var columns = function columns(ctx) {return [
  {
    label: '姓名',
    kname: 'userName' },

  {
    label: '成员类型',
    kname: 'role',
    filterFn: function filterFn(v) {
      return getLabel(peopleType, v);
    } },

  {
    label: '邀请人',
    kname: 'inviterName' },

  {
    label: '加入时间',
    kname: 'createAt' }];};



export var models = genModels(function (genRules, ctx) {return [
  {
    span: 12,
    kname: 'content',
    itemProps: {
      label: '消息内容' },

    component: {
      name: 'text' } },


  {
    span: 12,
    kname: 'createAt',
    itemProps: {
      label: '时间' },

    component: {
      name: 'text' } }];});




// form 默认值
export var formData = function formData() {return {
    li: [{}, {}, {}, {}, {}, {}, {}, {}] };};